<template>
  <div class="category">
    <div class="utils">
      所属模块：
      <el-select
        v-model="moduleSortUuid"
        @change="initModuleList"
        size="small"
      >
        <el-option
          label="全部"
          :value="''"
        ></el-option>
        <el-option
          v-for="item in categories"
          :key="item.moduleSortUuid"
          :label="item.name"
          :value="item.moduleSortUuid"
        >
        </el-option>
      </el-select>
      <el-button
        type="primary"
        style="float: right;"
        @click="handleAddCategory"
        size="small"
      >新增分类
      </el-button>
    </div>
    <el-table
      :data="modules"
      v-loading="loading"
    >
      <el-table-column
        label="序号"
        type="index"
        width="50px"
      ></el-table-column>
      <el-table-column
        label="分类名称"
        width="120px"
      >
        <template slot-scope="scope">
          <div v-show="!scope.row.isEdit">
            {{scope.row.name}}
          </div>
          <div v-show="scope.row.isEdit">
            <el-input v-model="scope.row.name" size="small"></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="图标"
        width="60px"
      >

        <template slot-scope="scope">
          <template v-if="scope.row.icon">
            <span
              :class="['iconfont','icon-module', scope.row.icon]"
              :style="{color: scope.row.color}"
              @click="handleVisible(scope.row)"
            >
            </span>
          </template>
          <template v-else>
            <el-button
              size="small"
              type="text"
              @click="handleVisible(scope.row)"
            >选择
            </el-button>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        label="是否免费"
        width="100px"
        align="center"
      >
        <template slot-scope="scope">
          <div v-show="!scope.row.isEdit">
            {{scope.row.isFree===1?'是':'否'}}
          </div>
          <div v-show="scope.row.isEdit">
            <el-radio-group class="block" v-model="scope.row.isFree" size="small">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </div>
        </template>

      </el-table-column>
      <el-table-column
        label="所属模块分类"
        width="128px"
      >
        <template slot-scope="scope">
          <div v-show="!scope.row.isEdit">
            {{scope.row.moduleName}}
          </div>
          <div v-show="scope.row.isEdit">
            <el-select v-model="scope.row.moduleSortUuid">
              <el-option
                v-for="it in categories"
                :key="it.moduleSortUuid"
                :label="it.name"
                :value="it.moduleSortUuid"
              >
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="英文名称"
        width="160px">
        <template slot-scope="scope">
          <div v-show="!scope.row.isEdit">
            {{scope.row.engName}}
          </div>
          <div v-show="scope.row.isEdit">
            <el-input v-model="scope.row.engName" size="small"></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作平台"
        width="100px"
      >
        <template slot-scope="scope">
          <div v-show="!scope.row.isEdit">
            {{scope.row.canUse | formatPlatform}}
          </div>
          <div v-show="scope.row.isEdit">
            <el-radio-group class="block" v-model="scope.row.canUse">
              <el-radio :label="0">全部</el-radio>
              <el-radio :label="1">小程序端</el-radio>
              <el-radio :label="2">pc端</el-radio>
            </el-radio-group>
          </div>

        </template>
      </el-table-column>
      <el-table-column label="跳转链接">
        <template slot-scope="scope">
          <div
            class="module-route-link"
            v-show="!scope.row.isEdit"
          >
            <div v-show="scope.row.webRouterName">
              P&nbsp;&nbsp;C:&nbsp;&nbsp;
              <span style="display: inline" class="primary">{{scope.row.webRouterName}}</span>
            </div>
            <div v-show="scope.row.mobilePage">
              移动:&nbsp;&nbsp;
              <span style="display: inline" class="primary">{{scope.row.mobilePage}}</span>
            </div>
          </div>
          <div
            class="module-route-link"
            v-show="scope.row.isEdit"
          >
            <div>
              P&nbsp;&nbsp;C:&nbsp;&nbsp;
              <el-input
                v-model="scope.row.webRouterName"
                size="small"
                style="width: 80%;"
              ></el-input>
            </div>
            <div>
              移动:&nbsp;&nbsp;
              <el-input
                v-model="scope.row.mobilePage"
                size="small"
                style="width: 80%;"
              ></el-input>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="序号"
        width="80px"
      >
        <template slot-scope="scope">
          <div v-show="!scope.row.isEdit">
            {{scope.row.order}}
          </div>
          <div v-show="scope.row.isEdit">
            <el-input v-model.number="scope.row.order"></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="100px"
      >
        <template slot-scope="scope">
          <div v-show="!scope.row.isEdit">
            <el-button
              type="text"
              size="small"
              @click="handleOpenAll(scope.row)"
            >全体开放
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="scope.row.isEdit = true"
            >编辑
            </el-button>
          </div>
          <div v-show="scope.row.isEdit">
            <el-button
              type="text"
              class="danger"
              size="small"
              @click="handleCancelAdd(scope.$index)"
            >取消
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="handleConfirmAdd(scope.row)"
            >确定
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="visible"
      width="708px"
      :append-to-body="true"
      title="选择图标"
    >
      <div class="icon-check">
        <ul class="icon-list">
          <li
            v-for="(item,i) in icons"
            :key="i"
            :class="{active: choosedIndex===i}"
            :style="{color: choosedIndex===i ? color: '#ccc'}"
            @click="choosedIndex=i"
          >
            <span
              class="iconfont "
              :class="item.icon"
            ></span>
          </li>
        </ul>
        <div class="color-choose">
          <span class="primary">选择颜色：</span>
          <el-color-picker
            size="small"
            v-model="color"
          ></el-color-picker>
        </div>
      </div>
      <div
        slot="footer"
        @click="getIcon(icons[choosedIndex]['icon'])"
      >
        <el-button type="primary">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {categories, moduleList, addModule, updateModule, moduleOpen} from '@/api/factory/application/module'
  import {ColorPicker} from 'element-ui'

  export default {
    inject: ['icons'],
    data() {
      return {
        loading: false,
        moduleSortUuid: '',
        choosedIndex: -1,
        color: '#26C6DA',
        modules: [],
        currentItem: null,
        visible: false,
        categories: []
      }
    },
    methods: {
      init() {
        this.loading = true
        categories().then(res => {
          if (res.code === 0) {
            this.categories = res.data.map(item => ({name: item.name, moduleSortUuid: item.uuid}))
          }
          return moduleList({moduleSortUuid: this.moduleSortUuid})
        }).then(res => {
          if (res.code === 0) {
            this.modules = res.data.map(item => {
              let moduleName = this.categories.filter(cate => cate.moduleSortUuid === item.moduleSortUuid)[0].name
              return {...item, moduleName, isEdit: false}
            }).sort((a, b) => a.order - b.order)
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
      initModuleList(moduleSortUuid) {
        this.loading = true
        moduleList({moduleSortUuid}).then(res => {
          if (res.code === 0) {
            res.data = res.data || []

            this.modules = res.data.map(item => {
              let c = this.categories.filter(cate => cate.moduleSortUuid === item.moduleSortUuid)
              let moduleName = ''
              if (c && c.length > 0) {
                moduleName = c[0].name
              }

              return {...item, isEdit: false, moduleName}
            }).sort((a, b) => a.order - b.order)

          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
      handleAddCategory() {
        this.modules.unshift({
          name: '',
          isFree: 0,
          canUse: 0,
          moduleSortUuid: '',
          icon: '',
          color: '',
          webRouterName: '',
          mobilePage: '',
          isEdit: true
        })
      },
      handleVisible(item) {
        if (item.isEdit) {
          this.currentItem = item
          this.color = item.color
          this.visible = true
        }
      },
      getIcon(icon) {
        if (this.currentItem) {
          this.currentItem.icon = icon
          this.currentItem.color = this.color
          this.visible = false
        }
      },

      handleOpenAll(module) {
        this.$confirm('是否整体全体开放', '提示').then(() => {
          moduleOpen(module.uuid).then(res => {
            this.$message.success('等待开放完成')
          })
        })
      },
      // 取消新增
      handleCancelAdd(index) {
        if (this.modules[index].uuid) {
          this.modules[index].isEdit = false
          return
        }
        this.$confirm('是否取消新增模块', '提示').then(() => {
          this.modules.splice(index, 1)
        })
      },
      // 新增模块
      handleConfirmAdd(item) {
        let data = item
        if (!data.name) {
          this.$message.error('请输入名称')
          return
        }
        if (!data.icon) {
          this.$message.error('请选择图标')
          return
        }

        if (!data.moduleSortUuid) {
          this.$message.error('请选择模块所属分类')
          return
        }
        if (!data.webRouterName) {
          data.webRouterName = 'empty'
        }
        if (!data.mobilePage) {
          data.mobilePage = 'empty'
        }
        if (data.uuid) {
          updateModule(data).then(res => {
            if (res.code === 0) {
              this.$message.success('新增成功')
              this.initModuleList(this.moduleSortUuid)
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          addModule(data).then(res => {
            if (res.code === 0) {
              this.$message.success('新增成功')
              this.initModuleList(this.moduleSortUuid)
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      handleToEdit(item) {
        this.$store.commit('SET_MODULE_DETAIL', item)
        this.$router.push({name: 'define.detail', params: {id: item.uuid}})
      }
    },
    mounted() {
      this.init()
    },
    filters: {
      formatPlatform(value) {
        if (value === 0) {
          return '全部'
        } else if (value === 1) {
          return '小程序'
        } else if (value === 2) {
          return 'PC端'
        } else {
          return '无匹配项'
        }
      }
    },
    components: {
      ElColorPicker: ColorPicker
    },
    watch: {
      $route(to, from) {
        if (from.name === 'define.detail') {
          this.initModuleList(this.moduleSortUuid)
        }
      }
    }
  }
</script>

<style lang="scss" scope>
  .category {
    .utils {
      margin: $padding 0;
    }
  }

</style>
